import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const AuthLayout = React.lazy(() => import("./containers/AuthLayout"));
const VerifyOtp = React.lazy(() => import("./containers/VerifyOtp"));

// Pages
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/ResetPassword/ResetPassword")
);


const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);

const Term = React.lazy(() => import("./views/pages/cms/Term"));
const Privacy = React.lazy(() => import("./views/pages/cms/Privacy"));
const About = React.lazy(() => import("./views/pages/cms/About"));
// delete user
const DeleteAccount = React.lazy(() =>
  import("./views/deleteAccount/DeleteAccount")
);

const App = () => {
  return (
    <Router>
      <Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <AuthLayout {...props} />}
          />
            <Route
            exact
            path="/verify-otp"
            name="Verify Otp"
            render={(props) => <VerifyOtp {...props} />}
          />
      
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/reset-password"
            name="passwordReset"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="ForgotPassword"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            path="/term-condition"
            name="term-condition"
            render={(props) => <Term {...props} />}
          />
          <Route
            path="/about-us"
            name="about-us"
            render={(props) => <About {...props} />}
          />
          <Route
            path="/privacy-policy"
            name="privacy-policy"
            render={(props) => <Privacy {...props} />}
          />
          <Route
            path="/delete-account"
            name="delete-account"
            render={(props) => <DeleteAccount {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
          <Route component={(props) => <AuthLayout {...props} />} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
